import { axiosInstance } from "../../utils/config";

export const fetchSnippets = () => axiosInstance.get("/snippets");

export const fetchCurrentUser = (token) =>
  axiosInstance.get("/api/v1/users/me/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

import React, { useState } from "react";
import SnippetCard from "../../components/SnippetCard/SnippetCard";
import SnippetsSkeleton from "../../components/SnippetsSkeleton/SnippetsSkeleton";
import ReactPaginate from "react-paginate";
import "./Snippets.css";
import { UilAngleLeft, UilAngleRight } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";

function Snippets({ snippets, isLoading, screenWidth }) {
    const Navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);

    const snippetsPerPage = 12;
    const pagesVisited = pageNumber * snippetsPerPage;

    const displaySnippets = snippets.slice(
        pagesVisited,
        pagesVisited + snippetsPerPage
    );

    const pageCount = Math.ceil(snippets.length / snippetsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div className={`p-2 mb-12 flex gap-4  text-black flex-wrap items-center justify-center`}>
                {!snippets.length && !displaySnippets.length ? (
                    // if snippets are  not yet loaded, show skeletons
                    <SnippetsSkeleton />
                ) : (
                    // If snippets are ready.
                    displaySnippets &&
                    displaySnippets.map((snippet, i) => (
                        <div key={i} onClick={() => Navigate("/review/" + snippet.id)}>
                            <SnippetCard snippet={snippet} key={snippet.id} />
                        </div>
                    ))
                )}
            </div>
            <div className="pagination-container my-2 flex max-w-full overflow-x-scroll">
                {!isLoading && (
                    <ReactPaginate
                        previousLabel={<UilAngleLeft />}
                        nextLabel={<UilAngleRight />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination-btn flex"}
                        previousLinkClassName={"previous-btn"}
                        nextLinkClassName={"next-btn"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"primary-bg pagination-active"}
                    />
                )}
            </div>
        </div>
    );
}

export default Snippets;

import React from 'react'
import "./SearchField.css";

function SearchField({bgColor}) {
  return (
    <div className={`search-field py-[0.2rem] px-2 w-[45%] ${bgColor} rounded-md flex items-center justify-start`}>
        <i className="ri-search-line grey-color mr-2 border-r-2 pr-1"></i>
        <input type="text" placeholder='Search here' className={`${bgColor} text-sm grey-color border-none outline-none w-[90%]`} />    
    </div>
  )
}

export default SearchField
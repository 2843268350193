import React, { useState } from "react";
import { trackClickEvent } from "../../analytics";
import FormsLayout from "../../Layouts/FormsLayout";
import InputField from "../../components/InputField/InputField";
import BlueButton from "../../components/BlueButton/BlueButton";
import { axiosInstance } from "../../utils/config";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Toast/Toast";
import TextArea from "../../components/TextArea/TextArea";

function Contact() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [userInfo, setUserInfo] = useState({
    names: "",
    email: "",
    message: "",
  });
  const [isContactingIn, setIsContactingIn] = useState(false);
  const [contactError, setContactError] = useState("");
  const [success, setSuccess] = useState("");

  const handleContact = (e) => {
    e.preventDefault();
    trackClickEvent(e);
    setContactError("");
    setIsContactingIn(true);
    axiosInstance
      .post(`/api/v1/token/contact`, userInfo)
      .then((tokenresponse) => {
        cookies.set("token", tokenresponse.data.auth_token, { path: "/" });
        setIsContactingIn(false);
        setUserInfo({ names: "", email: "" });
        setSuccess("Logged in successfully");
        setTimeout(() => {
          navigate("/featured");
        }, 1000);
      })
      .catch((error) => {
        setContactError(Object.values(error.response.data)[0][0]);
        setUserInfo({ names: "", email: "" });
        setIsContactingIn(false);
      });
  };

  return (
    <div className="relative">
      {success && <Toast message={success} type="success" />}
      <FormsLayout welcomeText="Hi, Talk to us!" activeTab="contact">
        <form className="w-full flex justify-center flex-col items-center">
          <h4 className="font-bold text-xl">Contact Us</h4>
          {contactError && (
            <div className="w-full text-center text-sm bg-red-100 px-4 py-2 rounded-sm text-red-900">
              {contactError}
            </div>
          )}
          <InputField
            handleChange={(e) => {
              trackClickEvent(e);
              setUserInfo({ ...userInfo, names: e.target.value });
            }}
            value={userInfo?.names}
            type="text"
            classNames="w-full"
            label="Names"
            placeholder="Enter your names"
          />

          <InputField
            handleChange={(e) => {
              trackClickEvent(e);
              setUserInfo({ ...userInfo, email: e.target.value });
            }}
            value={userInfo?.email}
            type="email"
            classNames="w-full"
            label="Email"
            placeholder="Enter your email"
          />
          <TextArea
            classNames="my-16"
            innerClassNames="h-[89px]"
            label="Message"
            placeholder="Enter your message"
            value={userInfo?.message}
            handleChange={(e) => {
              trackClickEvent(e);
              setUserInfo({ ...userInfo, message: e.target.value });
            }}
          />

          <div className="w-full" onClick={handleContact}>
            <BlueButton
              disabled={!userInfo.names || !userInfo.email}
              classNames="w-full py-3 flex justify-center items-center"
            >
              {isContactingIn ? "Contacting in..." : "Send"}
            </BlueButton>
          </div>
        </form>
      </FormsLayout>
    </div>
  );
}

export default Contact;

import {
    CREATE,
    FETCH_ALL,
    START_LOADING,
    END_LOADING,
    FILTER_SNIPPETS_BY_LANGUAGE,
} from "../../constants/actionTypes";

export const snippets = (
    state = { isLoading: false, snippets: [], totalSnippets: [] },
    action
) => {
    switch (action.type) {
        case FETCH_ALL:
            return {
                ...state,
                snippets: action.payload,
                totalSnippets: action.payload,
            };
        case FILTER_SNIPPETS_BY_LANGUAGE:
            return {
                ...state,
                snippets: action.payload,
            };
        case CREATE:
            return { ...state, snippets: [action.payload, ...state.snippets] };
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";

import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import NavBarMobile from "../../components/Mobile/NavBarMobile/NavBarMobile";
import MobileSideBar from "../../components/Mobile/MobileSideBar/MobileSideBar";
import { useSelector } from "react-redux";

function Terms({ token }) {
  let { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);
  const { isLoading } = useSelector((state) => state.users);
  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <>
      <div className="relative">
        {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
        <header className="pt-3 pb-[3.75rem] mb-3 about-header h-auto relative">
          {width < 834 ? (
            <NavBarMobile toggleShowSideBar={toggleShowSideBar} />
          ) : (
            <NavBar />
          )}
        </header>
        <main className="black-color">
          <h1>Terms & Conditions</h1>
          <p>
            Digital Outcomes operates each website that links to these Terms of
            Use and together with our applications, APIs, and online services
            (collectively, the “Site”), as well as our other services,
            offerings, and any associated distribution platforms and any public
            Digital Outcomes online social media accounts or platforms as well
            as the Digital Outcomes Connect member platform (“Platform“) and any
            other interactions (e.g., customer service inquiries, user
            conferences, etc.) you may have with us (together with our Site,
            collectively referred to as our “Services”). By accessing or using
            our Services, you are agreeing to these terms and conditions (the
            “Terms”) and concluding a legally binding contract with Digital
            Outcomes. Do not access or use our Services if you are unwilling or
            unable to be bound by the Terms.
          </p>
          <h3>A. Definitions</h3>
          <h3> 1. Parties </h3>
          <p>
            {" "}
            “You” and “your” refer to you, as a user of our Services. A “user”
            is someone who accesses, posts, edits, browses, or in any way uses
            our Services. “We,” “us,” and “our” refer to Digital Outcomes.
          </p>
          <h3> 2. Content </h3>
          <p>
            “Content” means text, images, photos, audio, video, location data,
            and all other forms of data or communication. “Your Content” means
            Content that you submit or transmit to, through, or in connection
            with our Services, such as ratings, reviews, compliments,
            invitations, check-ins, messages, and information that you publicly
            display or displayed in your account profile. “User Content” means
            Content that users submit or transmit to, through, or in connection
            with our Services. “Digital Outcomes Content” means Content that we
            create and make available in connection with our Services. “Third
            Party Content” means Content that originates from parties other than
            Digital Outcomes or its users, which is made available in connection
            with our Services. “Site Content” means all of the Content that is
            made available in connection with our Services, including Your
            Content, User Content, Third Party Content, and Digital Outcomes
            Content.
          </p>

          <h3>B. Changes to Terms of Use</h3>
          <h3>1. Changes to Terms of Use</h3>
          <p>
            We may modify the Terms from time to time. The most current version
            of these Terms will be located here. You understand and agree that
            your access to or use of the Services is governed by the Terms
            effective at the time of your access to or use of the Services. If
            we make material changes to these Terms, we will notify you by email
            or by posting a notice on our site before the changes are effective.
            We will also indicate at the top of this page the date that
            revisions were last made. You should revisit these Terms on a
            regular basis as revised versions will be binding on you. Any such
            modification will be effective upon our posting of new Terms. You
            understand and agree that your continued access to or use of the
            Services after the effective date of modifications to the Terms
            indicates your acceptance of the modifications.
          </p>
          <h3>C. Using our Services</h3>
          <h3>1. Eligibility</h3>
          <p>
            To access or use our Services, you must be 18 years or older and
            have the requisite power and authority to enter into these Terms.
            You may not access or use our Services if we have previously banned
            you from our Site.
          </p>
          <h3>2. Permission to Use the Site</h3>
          <p>
            We grant you permission to use our Services subject to the
            restrictions in these Terms. Your use of our Services is at your own
            risk.
          </p>
          <h3>3. Site Availability</h3>
          <p>
            Our Services may be modified, updated, interrupted, suspended or
            discontinued at any time without notice or liability.
          </p>
          <h3>4. Communications from Digital Outcomes and other Users</h3>
          <p>
            You agree to receive certain communications in connection with your
            use of our Services. For example, you might receive questions or
            requests from other Users related to information or events you post
            on using our Services. If you have subscribed to them, you also will
            receive reading lists and email newsletters about happenings in your
            area. We provide unsubscribe links in all of our email footers if
            you no longer wish to receive those communications from us.
          </p>
          <h3>D. Content</h3>
          <h3>1. Content Guidelines</h3>
          <p>
            The purpose of our Services is to promote a thriving entrepreneurial
            ecosystem and to catalyze the growth and success of our cohort
            startups through mentoring, shared knowledge and experience as well
            as access to our professional networks. . They are not intended to
            be utilized as a forum for self-promotion or for commercial gain by
            its users (e.g. users are not permitted to promote personal business
            interests or services through using our Services).
          </p>
          <h3>2. Responsibility for Your Content</h3>
          <p>
            You alone are responsible for Your Content and assume all risks
            associated with Your Content. You represent that you own, or have
            the necessary permissions to use and authorize the use of Your
            Content as described herein. You may expose yourself to liability
            if, for example, (i) Your Content contains material that is false,
            intentionally misleading, or defamatory; (ii) violates any
            third-party right, including any copyright, trademark, patent, trade
            secret, moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right; (iii) contains material
            that is unlawful, including illegal hate speech or pornography; (iv)
            exploits or otherwise harms minors; or (v) violates or advocates the
            violation of any law or regulation.
          </p>
          <h3>3. Our Right to Use Your Content</h3>
          <p>
            We may use Your Content in a number of different ways, including
            publicly displaying it, reformatting it, incorporating it into other
            works, creating derivative works from it, promoting it, distributing
            it, and allowing others to do the same in connection with their own
            websites and media platforms (“Other Media”). As such, you hereby
            irrevocably grant us world-wide, perpetual, non-exclusive,
            royalty-free, assignable, sublicensable, transferable rights to use
            Your Content for any purpose. Please note that you also irrevocably
            grant the users of our Services and any Other Media the right to
            access Your Content in connection with their use of our Services and
            any Other Media. Finally, you irrevocably waive, and cause to be
            waived, against Digital Outcomes and its users any claims and
            assertions of moral rights or attribution with respect to Your
            Content. By “use” we mean use, copy, publicly perform and display,
            reproduce, distribute, modify, translate, remove, analyze,
            commercialize, and prepare derivative works of Your Content.
          </p>
          <h3>4. Ownership</h3>
          <p>
            As between you and Digital Outcomes, you own Your Content. We own
            the Digital Outcomes Content, including but not limited to the
            visual interfaces, interactive features, graphics, design and all
            other elements and components of our Services excluding Your
            Content, User Content, and Third Party Content. We also own the
            copyrights, trademarks, service marks, trade names, and other
            intellectual and proprietary rights throughout the world (“IP
            Rights”) associated with the Digital Outcomes Content and our
            Services, which are protected by copyright, trade dress, patent,
            trademark laws and all other applicable intellectual and proprietary
            rights and laws. As such, you may not modify, reproduce, distribute,
            create derivative works or adaptations of, publicly display or in
            any way exploit any of the Digital Outcomes Content in whole or in
            part except as expressly authorized by us. Except as expressly and
            unambiguously provided herein, we do not grant you any express or
            implied rights, and all rights in and to our Services and the
            Digital Outcomes Content are retained by us.
          </p>
          <h3>5. Other</h3>
          <p>
            We reserve the right to remove, screen, edit, or reinstate User
            Content from time to time at our sole discretion for any reason or
            no reason, and without notice to you. We have no obligation to
            retain or provide you with copies of Your Content, nor do we
            guarantee any confidentiality with respect to Your Content.
          </p>
          <h3>E. Restrictions</h3>
          <h3>1. Restriction 1</h3>
          <p>
            You agree not to, and will not assist, encourage, or enable others
            to use our Services to: (i) violate our Content or Event Guidelines
            as set forth in these Terms; (ii) violate any third party's rights,
            including any breach of confidence, copyright, trademark, patent,
            trade secret, moral right, privacy right, right of publicity, or any
            other intellectual property or proprietary right; (iii) threaten,
            stalk, harm, or harass others, or promote bigotry or discrimination;
            (iv) send bulk emails, surveys, or other mass messaging, whether
            commercial in nature or not; or (v) violate any applicable law.
          </p>
          <h3>2. Restriction 2</h3>
          <p>
            You also agree not to, and will not assist, encourage, or enable
            others to: (i) violate the Terms; (ii) modify, adapt, appropriate,
            reproduce, distribute, translate, create derivative works or
            adaptations of, publicly display, sell, trade, or in any way exploit
            our Services or Site Content (other than Your Content), except as
            expressly authorized by Digital Outcomes; (iii) reverse engineer any
            portion of our Services; (iv) remove or modify any copyright,
            trademark or other proprietary rights notice that appears on any
            portion of our Services or on any materials printed or copied from
            our Services Record; (v) process, or mine information about other
            companies and/or users; (vi)eformat or frame any portion of our
            Services; (vii) attempt to gain unauthorized access to our Services,
            user accounts, computer systems or networks connected to our
            Services through hacking, password mining or any other means; (viii)
            use our Services or any Site Content to transmit any computer
            viruses, worms, defects, Trojan horses or other items of a
            destructive nature (collectively, “Viruses”); (ix) use any device,
            software or routine that interferes with the proper working of our
            Services, or otherwise attempt to interfere with the proper working
            of our Services; (x) use our Services to violate the security of any
            computer network, crack passwords or security encryption codes; (xi)
            disrupt or interfere with the security of, or otherwise cause harm
            to, our Services or Site Content; or (xii) remove, circumvent,
            disable, damage or otherwise interfere with any security-related
            features of our Services, features that prevent or restrict the use
            or copying of Site Content, or features that enforce limitations on
            the use of our Services.
          </p>
          <h3>F. Events</h3>
          <h3>1. Events and Acceptance of Terms</h3>
          <p>
            As used below, the term “UP Event” means an event, gathering,
            promotion, contest, meet-up or the like that is organized or hosted
            by Digital Outcomes. A “Non-UP Event” means an event, gathering,
            promotion, contest, meet-up or the like that is organized or hosted
            by someone other than Digital Outcomes, but that may appear or be
            listed on our Services. In order to participate or attend a UP Event
            you must agree to the terms in this Section 6. If you find that you
            cannot or will not agree to such terms, please do not RSVP to or
            attend any UP Event. If you RSVP to any UP Event or Non-UP Event
            through our Services, you signal that you have read, understand and
            agree to these event terms.
          </p>
          <h3>2. Assumptions of risk/release of liability/indemnity</h3>
          <p>
            Although we try to create a safe environment at UP Events, accidents
            happen. We ask that you do your part to help limit the possibility
            that you might get injured at a UP Event. You agree to carefully
            consider the inherent risks in any activities that you choose to
            take part in and to take reasonable precautions before you choose to
            attend or participate at a UP Event. For example, you should ensure
            that you are in good physical health before engaging in any physical
            activity at a UP Event, and you should always drink responsibly
            given the risks associated with drunk driving and participating in
            activities while your judgment is impaired. Second, please note that
            some of the other UP Event guests may be less responsible than you,
            and may themselves create additional risks to you despite their best
            intentions. In summary, you understand that your attendance and
            participation at UP Events is voluntary, and you agree to assume
            responsibility for any resulting injuries to the fullest extent
            permitted under applicable law. By the same token, you agree that
            Digital Outcomes is not responsible for any injuries you might
            sustain at Non-UP Events listed on our Services. You (for yourself,
            your heirs, personal representatives, or assigns, and anyone else
            who might make a claim on your behalf) hereby release, waive,
            discharge and covenant not to sue, Digital Outcomes and its
            respective parent companies, subsidiaries, affiliates, officers,
            directors, partners, shareholders, members, agents, employees,
            vendors, sponsors and volunteers from any and all claims, demands,
            causes of action, damages, losses or expenses (including court costs
            and reasonable attorneys' fees) which may arise out of, result from,
            or relate in any way from your attendance at any UP Event or Non-UP
            Event, except for any gross negligence or willful misconduct on our
            part. You further agree to indemnify and hold Digital Outcomes, its
            parents, subsidiaries, affiliates, officers, directors, employees,
            agents and representatives harmless, including costs, liabilities,
            and legal fees, from any claim or demand made by any third party due
            to, related to, or connected with your attendance or conduct at any
            UP Event or Non-UP Event.
          </p>
          <h3>3. Photos</h3>
          <p>
            Digital Outcomes and its sponsors and vendors sometimes take photos
            and videos at UP Events, and later use them for promotional
            purposes. By participating or attending any UP Event, you agree that
            you may appear in some of these photos and videos, and you authorize
            their use in this fashion. In addition, if you take any photos or
            videos of attendees at a UP Event and provide them to us, you
            authorize us to use them in the same fashion and represent that the
            attendees that appear in your photos and videos have consented to
            such use. We do not control Non-UP Events or the people that
            organize them. It is possible that organizers or attendees of Non-UP
            Events will take photos or shoot videos in which you appear and then
            post them online (including, possibly, on our Services). By RSVP'ing
            to a Non-UP event through our Services, you authorize Digital
            Outcomes's use of such media for promotional purposes.
          </p>
          <h3>4. Termination</h3>
          <p>
            While we hope not to, we may prohibit your attendance at any UP
            Event at any time if you fail to abide by the Terms, including this
            Section 6, or for any or no reason, without notice or liability of
            any kind. We reserve the right to cancel any UP Event at any time,
            for any reason, and without liability or prejudice.
          </p>
          <h3>5. Unofficial Events</h3>
          <p>
            From time to time, members of the Digital Outcomes community
            organize their own meet-ups and events. Although Digital Outcomes
            representatives sometimes join in the fun, such events are Non-UP
            Events and are not sponsored or endorsed by Digital Outcomes, even
            when promoted through our Services. All terms and conditions related
            to Non-UP Events apply equally to such events. If you organize such
            an event, you agree not to create the impression that it is
            sponsored or endorsed by Digital Outcomes. You agree that you will
            not represent yourself as an employee, representative, or agent of
            Digital Outcomes (unless, of course, you are actually employed by
            Digital Outcomes, in which case these Terms will govern your
            participation in any UP Event to the extent such Terms are not
            inconsistent with Digital Outcomes's standard employment agreements
            or policies).
          </p>
          <h3>G. Privacy and Copyright/Trademark Disputes</h3>
          <h3>1. Privacy</h3>
          <p>
            Your use of our Services is subject to Digital Outcomes's Privacy
            Policy available here.
          </p>
          <h3>2. Copyright/Trademark Disputes</h3>
          <p>
            If you believe that your copyright or trademark is being infringed
            through our Services, please send us a written notice with the
            following information: (i) identification of the copyrighted or
            trademarked work that you claim has been infringed; (ii)
            identification of the allegedly infringing content, and information
            reasonably sufficient to permit Digital Outcomes to locate it on our
            Site (e.g., the URL for the web page on which the content appears);
            (iii) a statement by you that you have a good faith belief that the
            use of the content identified in your notice in the manner
            complained of is not authorized by the copyright/trademark owner,
            its agent, or the law; (iv) a statement by you that you attest,
            under penalty of perjury, that the information in your notice is
            accurate and that you are the copyright/trademark owner or
            authorized to act on the owner’s behalf; and (v) your physical or
            electronic signature, together with your contact information
            (address, telephone number and, if available, email address).If you
            believe that your content should not have been removed for alleged
            copyright or trademark infringement, you may send us a written
            counter-notice with the following information: (i) identification of
            the copyrighted or trademarked work that was removed, and the
            location within our Services where it would have been found prior to
            its removal; (ii) a statement, under penalty of perjury, that you
            have a good faith belief that the content was removed as a result of
            a mistake or misidentification. For trademark disputes only: (iii)
            information reasonably sufficient to explain why you believe you are
            not infringing the trademarked work; and (iv) a statement that you
            consent either to the jurisdiction of (a) the Federal District Court
            for the judicial district in which your address is located if you
            live in the United States, or (b) any judicial district in which
            Digital Outcomes is located if you live outside the United
            States.Please also include: (i) a statement that you will accept
            service of process from the person who sent the original
            infringement notice to Digital Outcomes, or an agent of such person;
            and (ii) your physical or electronic signature, together with your
            contact information (address, telephone number and, if available,
            email address). We will respond to all such notices and comply with
            applicable law. We reserve the right to remove content alleged to be
            infringing without prior notice and at our sole discretion.
          </p>
          <h3>H. Miscellaneous</h3>
          <h3>1. Suggestions and Improvements</h3>
          <p>
            By sending us any ideas, suggestions, documents or proposals
            (“Feedback”), you agree that (i) your Feedback does not contain the
            confidential or proprietary information of third parties; (ii) we
            are under no obligation of confidentiality, express or implied, with
            respect to the Feedback; (iii) we may have something similar to the
            Feedback already under consideration or in development; and (iv) you
            grant us an irrevocable, non-exclusive, royalty-free, perpetual,
            worldwide license to use, modify, prepare derivative works, publish,
            distribute and sublicense the Feedback, and you irrevocably waive,
            and cause to be waived, against Digital Outcomes and its users any
            claims and assertions of any moral rights contained in such
            Feedback.
          </p>
          <h3>2. Third Party Sites</h3>
          <p>
            Our Services may contain links to third party websites. When you
            click on a link to any other website or location, you will leave our
            Services and you will be subject to the terms of use and privacy
            policy applicable to those websites. We have no control over, do not
            review, and cannot be responsible for, these outside websites or
            their content, and your use of such websites is at your own risk.
            The links to third party websites or locations are for your
            convenience and do not signify our endorsement of such third parties
            or their products, content or websites. Some of the services made
            available through our Services may be subject to additional third
            party or open source licensing terms and disclosures, which are
            incorporated herein by reference.
          </p>
          <h3>3. Indemnity</h3>
          <p>
            You understand and agree that you are personally responsible for
            your behavior on our Services. You agree to indemnify, defend, and
            hold Digital Outcomes, its parents, subsidiaries, affiliates, any
            related companies, suppliers, licensors and partners, and the
            officers, directors, employees, agents and representatives of each
            of them (collectively, the “Digital Outcomes Entities“) harmless,
            including costs, liabilities and legal fees, from any claim or
            demand made by any third party arising out of or relating to: (i)
            your access to or use of our Services; (ii) your violation of the
            Terms; or (iii) the infringement by you, or any third party using
            your account, of any intellectual property or other right of any
            person or entity. Digital Outcomes reserves the right, at your
            expense, to assume the exclusive defense and control of any matter
            for which you are required to indemnify us and you agree to
            cooperate with our defense of these claims. You agree not to settle
            any such matter without the prior written consent of Digital
            Outcomes. Digital Outcomes will use reasonable efforts to notify you
            of any such claim, action or proceeding upon becoming aware of it.
          </p>
          <h3>4. Disclaimers</h3>
          <p>
            PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF
            THE DIGITAL OUTCOMES ENTITIES TO YOU. EACH OF THE SUBSECTIONS BELOW
            ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
            LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE
            WHICH MAY NOT BE LAWFULLY LIMITED. IF YOU ARE UNSURE ABOUT THIS OR
            ANY OTHER SECTION OF THESE TERMS, PLEASE CONSULT WITH A LEGAL
            PROFESSIONAL PRIOR TO ACCESSING OR USING OUR SERVICES. BY ACCESSING
            OR USING OUR SERVICES, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD,
            AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP
            SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS. A. OUR SERVICES
            ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS
            AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE DIGITAL
            OUTCOMES ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT. AS
            SUCH, YOUR USE OF OUR SERVICES IS AT YOUR OWN DISCRETION AND RISK.
            THE DIGITAL OUTCOMES ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE
            QUALITY, ACCURACY, OR RELIABILITY OF OUR SERVICES, ITS SAFETY OR
            SECURITY, OR THE SITE CONTENT. ACCORDINGLY, THE DIGITAL OUTCOMES
            ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT
            ARISE, FOR EXAMPLE, FROM OUR SERVICE’S INOPERABILITY, UNAVAILABILITY
            OR SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY,
            ACCURACY, OR RELIABILITY OF OUR SERVICES, OR INFORMATION FOUND ON,
            OR MADE AVAILABLE THROUGH OUR SERVICES. B. THE DIGITAL OUTCOMES
            ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY,
            SUCH AS THE BUSINESSES OR EVENTS HOSTED BY THIRD PARTIES LISTED ON
            OUR SERVICES OR OUR SERVICE’S USERS. ACCORDINGLY, THE DIGITAL
            OUTCOMES ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT
            MIGHT ARISE FROM THEIR ACTIONS OR OMISSIONS. YOUR ATTENDANCE AT
            EVENTS AND/OR USE OF SERVICES OFFERED BY THIRD PARTIES THROUGH OUR
            SERVICES IS AT YOUR OWN DISCRETION AND RISK. C. THE DIGITAL OUTCOMES
            ENTITIES EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR
            IMPLIED, INCLUDING WARRANTIES AS TO THE EVENTS LISTED ON OUR
            SERVICES, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. D. YOUR SOLE AND EXCLUSIVE
            RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH OUR SERVICES OR ANY
            OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF
            ACCESS TO, OR USE OF OUR SERVICES. E. THE DIGITAL OUTCOMES ENTITIES’
            MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU
            SUFFER IN CONNECTION WITH OUR SERVICES OR THESE TERMS IS LIMITED TO
            THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE DIGITAL
            OUTCOMES ENTITIES IN CONNECTION WITH OUR SERVICES IN THE 12 MONTHS
            PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100. F. THE
            DIGITAL OUTCOMES ENTITIES DISCLAIM LIABILITY FOR ANY (i) INDIRECT,
            SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL
            DAMAGES, (ii) LOSS OF PROFITS, (iii) BUSINESS INTERRUPTION, (iv)
            REPUTATIONAL HARM, OR (v) LOSS OF INFORMATION OR DATA.
          </p>
          <h3>5. Choice of Law and Venue</h3>
          <p>
            Delaware law will govern these Terms, as well as any claim, cause of
            action or dispute that might arise between you and Digital Outcomes
            (a “Claim”), without regard to conflict of law provisions. FOR ANY
            CLAIM BROUGHT BY EITHER PARTY, YOU AGREE TO SUBMIT AND CONSENT TO
            THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE
            OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN THE STATE OF
            DELAWARE.
          </p>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default Terms;

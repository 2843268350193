import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo_dark.png";
import SearchField from "../SearchField/SearchField";
import BlueButton from "../BlueButton/BlueButton";
import "./LightNavBar.css";
import { UilPlus, UilSignOutAlt } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import AccountDropDown from "../AccountDropDown/AccountDropDown";
import { getCurrentUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import useWindowDimensions from "../../utils/useWindowDimensions";
import MobileLightNavBar from "../Mobile/MobileLightNavBar/MobileLightNavBar";
import MobileSideBar from "../Mobile/MobileSideBar/MobileSideBar";

function LightNavBar({ active }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  const cookies = new Cookies();
  const tokenCookie = cookies.get("token");

  useEffect(() => {
    if (tokenCookie) {
      dispatch(getCurrentUser(tokenCookie));
    }
  }, [tokenCookie, dispatch]);

  const { me } = useSelector((state) => state.users);
  return (
    <nav
      className={`bg-white text-sm flex items-center z-50 justify-between ${
        width < 834 ? `p-0` : `p-4`
      } py-4 light-navbar sticky top-0`}
    >
      {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
      {width < 834 ? (
        <MobileLightNavBar toggleShowSideBar={toggleShowSideBar} />
      ) : (
        <>
          <div className="left flex items-center w-[35%] justify-between">
            <Link to="/" className="cursor-pointer">
              <img src={Logo} alt="" />
            </Link>
            <Link
              className={`${active === "explore" && "active"}`}
              to="/featured"
            >
              Explore
            </Link>
            {/*
            <Link
              to="/waitlist"
              className={`${active === "waitlist" && "active"}`}
            >
              Join waitlist
            </Link>
            */}
            <Link
              to="/contact"
              className={`${active === "contact" && "active"}`}
            >
              Contact us
            </Link>
          </div>
          <div className="right flex items-center w-[35%] justify-around">
            <SearchField bgColor="light-grey-bg" />
            <BlueButton redirectTo="/submit" preIcon={<UilPlus size="15" />}>
              Submit
            </BlueButton>
            {tokenCookie ? (
              <AccountDropDown me={me} />
            ) : (
              <BlueButton
                redirectTo="/login"
                preIcon={<UilSignOutAlt size="15" />}
              >
                Login
              </BlueButton>
            )}
          </div>
        </>
      )}
    </nav>
  );
}

export default LightNavBar;

import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Snippets from "../../sections/Snippets/Snippets";
import Footer from "../../components/Footer/Footer";

const User = () => {
  const { snippets, isLoading, totalSnippets } = useSelector(
    (state) => state.snippets
  );
  console.log({ snippets, isLoading, totalSnippets });
  const { width } = useWindowDimensions();

  const [tabValue, setTabValue] = useState(0);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <LightNavBar active="explore" />
      <Box sx={{ width: "80%", margin: "auto", mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          User Profile
        </Typography>

        {/* MUI Tabs */}
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Questions Posted" />
          <Tab label="Commented Questions" />
        </Tabs>

        <Box sx={{ mt: 3 }}>
          {/* Conditional rendering based on active tab */}
          {tabValue === 0 && (
            <Snippets
              snippets={snippets}
              isLoading={isLoading}
              screenWidth={width}
            />
          )}
          {tabValue === 1 && (
            <Snippets
              snippets={snippets}
              isLoading={isLoading}
              screenWidth={width}
            />
          )}
        </Box>
      </Box>
      <Footer />
      </div>
  );
};
export default User;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LanguageTag from "../../components/LanguageTag/LanguageTag";
import filterArray from "../../utils/groupBy";
import {
  END_LOADING,
  FILTER_SNIPPETS_BY_LANGUAGE,
  START_LOADING,
} from "../../constants/actionTypes";
import { useNavigate, useParams } from "react-router-dom";

function Languages({ snippets, isLoading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [languages, setLanguages] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState("");

  const filterSnippetsByLanguage = () => {
    let filteredSnippets = snippets.filter(
      (snippet) => snippet.language === activeLanguage.toLowerCase()
    );
    dispatch({ type: START_LOADING });
    dispatch({ type: FILTER_SNIPPETS_BY_LANGUAGE, payload: filteredSnippets });
    dispatch({ type: END_LOADING });
  };

  useEffect(() => {
    if (activeLanguage) {
      filterSnippetsByLanguage();
    }
  }, [activeLanguage]);

  useEffect(() => {
    const groupedSnippets = filterArray(snippets, "language");
    let groupedLanguages = Object.keys(groupedSnippets).map((k) => ({
      language: k,
      numSnippets: groupedSnippets[k].length,
    }));
    setLanguages(groupedLanguages);
  }, [snippets, isLoading]);

  const params = useParams();

  useEffect(() => {
    setActiveLanguage(params.language);
  }, [params]);

  const activateLanguage = (lang) => {
    setActiveLanguage(lang.language);
    navigate("/featured/" + lang.language);
  };

  return (
    <div className="header p-4 flex justify-start items-center">
      <h6 className="text-md font-bold items-center mr-5">
        <span className="flex items-center justify-center text-sm">
          {isLoading ? (
            "Loading featured reviews"
          ) : (
            <>
              <span className="primary-color mr-2">
                {snippets.length ? snippets.length : "..."}
              </span>{" "}
              <span>Featured reviews</span>
            </>
          )}
        </span>
      </h6>
      <div className="languages-tags flex my-6 items-center justify-end overflow-x-hidden">
        {/* <Carousel autoPlay> */}
        {languages &&
          languages.map((lang, index) => (
            // a button for choosing a language
            <div key={index} onClick={() => activateLanguage(lang)}>
              <LanguageTag
                active={activeLanguage === lang.language ? true : false}
                lang={lang.language}
                numSnippets={lang.numSnippets}
              />
            </div>
          ))}
        {/* </Carousel> */}
      </div>
    </div>
  );
}

export default Languages;

import React from 'react'
import Logo from "../../../assets/images/black_transparent_logo.png";
import { UilMultiply, UilPlus, UilSignOutAlt } from '@iconscout/react-unicons';
import "./MobileSideBar.css";
import Button from '../../Button/Button';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGOUT } from '../../../constants/actionTypes';

function MobileSideBar({ toggleShowSideBar, styles }) {
    const cookies = new Cookies();
    const userCookie = cookies.get("token");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = () => {
        cookies.remove("token");
        dispatch({ type: LOGOUT })
        navigate("/");
    }
    return (
        <div className='h-auto mobile-sidebar font-poppins w-10/12 z-50 bg-[#EFEFEF] absolute top-0 py-6 pr-4 pl-8'>
            <div className="top-header flex w-full items-center justify-between">
                <img src={Logo} className="h-[18px]" alt="" />
                <div className="close-icon cursor-pointer" onClick={toggleShowSideBar}>
                    <UilMultiply />
                </div>
            </div>
            <ul className="sidebar-list-items my-8">
                <li className='primary-color text-sm' onClick={() => navigate("/featured")}>Explore</li>
                { /*<li className='text-sm' onClick={() => navigate("/waitlist")}>Join Waitlist</li> */ }
                <li className='text-sm' onClick={() => navigate("/contact")}>Contact us</li>
            </ul>
            <Button redirectTo="/submit" parentStyles="px-6 mb-5 py-2 font-medium" preIcon={<UilPlus size="15" />}>
                Submit
            </Button>
            {userCookie ? (
                <div onClick={logout}>
                    <Button preIcon={<UilSignOutAlt size="15" />}>
                        Logout
                    </Button>
                </div>
            ) : (
                <Button redirectTo="/login" parentStyles="px-7 my-3 py-2 font-medium" preIcon={<UilSignOutAlt size="15" />}>
                    Login
                </Button>
            )}
            <Button redirectTo="/featured" parentStyles="px-7 my-3 py-2 font-medium" preIcon={<UilSignOutAlt size="15" />}>
                Go to snippets
            </Button>

            <div className="ads-container mt-6">

                <div className="grey-bg h-[350px] rounded-lg flex items-center justify-center">
                    ads
                </div>
                <div className="grey-bg mt-3 h-[350px] rounded-lg flex items-center justify-center">
                    ads
                </div>
            </div>
        </div>
    )
}

export default MobileSideBar

import React from "react";
import { parseIfJson } from "../../utils/parsetJson";

function SnippetCard({ snippet }) {
    return (
        <div className="snippet-card-container w-[306px] h-auto bg-[#C5D2EF] rounded-md px-[0.4rem] py-2 pb-1 m-2 hover:shadow-md hover:scale-105 cursor-pointer hover:animate-pulse duration-[0.2s]">

            <div className="py-1 flex items-center justify-start gap-2">
                <span className="font-medium badge primary-bg text-white text-xs rounded-md px-[0.4rem] py-1">
                    {snippet.language?.charAt(0).toUpperCase() +
                        snippet.language.slice(1)}
                </span>
                <span className="text-xs font-medium">
                    {snippet.title.length + snippet.language.length > 28
                        ? `${snippet.title.slice(0, 28)}...`
                        : snippet.title}
                </span>
            </div>
            <div
                className="content w-[99%] p-2 m-auto my-1 bg-white h-[160px] rounded-md overflow-hidden"
                style={{ fontFamily: "Roboto !important" }}
            >
                <div className="whitespace-pre-wrap text-black text-xs snippet">
                    {parseIfJson(snippet.text)}
                </div>
            </div>
            <div className="footer w-full flex my-2 items-center justify-end">
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.8 0H1.2C0.54 0 0 0.54 0 1.2V12L2.4 9.6H10.8C11.46 9.6 12 9.06 12 8.4V1.2C12 0.54 11.46 0 10.8 0ZM6.942 5.742L6 7.8L5.058 5.742L3 4.8L5.058 3.858L6 1.8L6.942 3.858L9 4.8L6.942 5.742Z"
                        fill="black"
                    />
                </svg>
                <span className="text-xs ml-1" data-testid="comment-number">
                    {" "}
                    {snippet.comments ? snippet.comments.length : "..."} Reviews
                </span>
            </div>
        </div>
    );
}

export default SnippetCard;

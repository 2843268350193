import React from "react";
import Button from "../Button/Button";
import Languages from "../../assets/icons/languages.svg";
import Banner1 from "../../assets/images/banner1.png";
import Banner2 from "../../assets/images/banner2.png";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner-container px-16 mt-[5rem] text-white mb-8">
      <h1 className="text-[30px] font-bold">
        What would seniors say <br /> about your code!
      </h1>
      <p className="my-3 font-medium">
        Most companies do not tell why they rejected you. We do. <br /> Submit
        your code and get feedback.
      </p>
      <Button
        parentStyles="h-[34px] light-grey-bg my-5"
        childStyles="primary-color font-bold"
        redirectTo="/login"
      >
        Join Now
      </Button>
      <div className="under-banner flex justify-start relative">
        <img src={Languages} className="ml-14 mr-20" alt="" />
        <div className="under-banner__banner-imgs flex ml-[2rem]">
          <img src={Banner2} alt="" />
          <img
            src={Banner1}
            className="absolute top-[-14.5rem] right-[4rem]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;

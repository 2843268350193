
import React from 'react'
import { useSelector } from "react-redux";
import Footer from '../../components/Footer/Footer';
import LightNavBar from '../../components/LightNavBar/LightNavBar'
import Languages from '../../sections/Languages/Languages'
import Snippets from '../../sections/Snippets/Snippets';
import useWindowDimensions from '../../utils/useWindowDimensions';
import "./Featured.css";

function Featured() {
    const { width } = useWindowDimensions();
    const { snippets, isLoading, totalSnippets } = useSelector(state => state.snippets);

    return (
        <div className="featured-container text-black w-[100vw] h-[100vh] white-bg">
            <LightNavBar active="explore" />
            <Languages snippets={totalSnippets} isLoading={isLoading} />
            <Snippets snippets={snippets} isLoading={isLoading} screenWidth={width} />
            <Footer />
        </div>
    )
}

export default Featured
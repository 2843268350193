import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";

import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import NavBarMobile from "../../components/Mobile/NavBarMobile/NavBarMobile";
import MobileSideBar from "../../components/Mobile/MobileSideBar/MobileSideBar";
import { useSelector } from "react-redux";

function Privacypolicy({ token }) {
  let { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);
  const { isLoading } = useSelector((state) => state.users);
  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <>
      <div className="relative">
        {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
        <header className="pt-3 pb-[3.75rem] mb-3 about-header h-auto relative">
          {width < 834 ? (
            <NavBarMobile toggleShowSideBar={toggleShowSideBar} />
          ) : (
            <NavBar />
          )}
        </header>
        <main className="black-color">
          <h1>Privacy Policy</h1>
          <p>
            This Privacy Policy applies to all websites that we own and operate,
            including the website located at https://www.digitaloutcomes.io/ and
            all related websites, our applications and/or APIs, and online
            services (our “Site“), as well as the Digital Outcomes member
            platform (“Platform“), and other interactions (e.g., customer
            service inquiries, or your participation in user conferences,
            community events, etc.) you may have with us that are subject to
            this Privacy Policy (together with our Site, collectively referred
            to as our “Services“). If you do not agree with the data practices
            described in this Privacy Policy, you should not use our Services.
            This Privacy Policy does not apply to any third-party applications
            or software that integrate with the Services through our Site
            (“Third Party Services“), or any other third party products,
            services or businesses.
          </p>
          <h4>Information We Collect and Receive</h4>
          <p>
            We collect and receive information that relates to identified or
            identifiable individuals (“personal information“) as follows:
            Identity Data: Information such as your name; address; email
            address; telephone number; gender; date of birth, age and/or age
            range; photo/avatar; account login details (e.g., username and
            password); and information such as unique IDs and online
            identifiers.
          </p>
          <p>
            Contact Data: Identity Data that includes personal and business
            contact information, such as postal address, email address,
            telephone number, or other identifiers used for communication.
          </p>
          <p>
            Content: Unstructured or free-form content that you post on our Site
            or Platform that may include personal data (e.g., text, images,
            photographs, messages, comments, feedback and correspondence), such
            as information you provide in your responses to surveys, when you
            participate in market research activities, report a problem with our
            Site, receive customer support or otherwise correspond with us.
          </p>
          <p>
            Financial Data: Information such as bank account details, payment
            card information, and information from credit reference agencies,
            including similar data defined in Cal. Civ. Code § 1798.80(e).
          </p>
          <p>
            Commercial Data: Information from transactions and purchases you
            make through the Site, registrations through our Site, and billing
            details.
          </p>
          <p>
            Device/Network Data: Information regarding your interaction with a
            website, application, or advertisement (e.g., IP Address, MAC
            Address, SSIDs, etc.), online user ID, device characteristics (such
            as browser/OS version), web server logs, application logs, cookie
            data, web beacons, clear GIFs, pixel tags, and information about how
            you use our Site and interact with us.
          </p>
          <p>
            Audio/Visual Data: Recordings such as audio files and records (e.g.,
            voice mails, call recordings, and the
            like).Professional/Biographical Data: Data relating to professional
            and employment history, qualifications, and similar biographic
            information.
          </p>
          <p>
            Government ID Data: Information relating to official government
            identification, such as driver’s license or passport numbers, social
            security numbers, etc., including similar Identity Data included in
            Cal Civ Code § 1798.80(e).
          </p>
          <h4>How We Collect Personal Information</h4>
          <p> Information you give us</p>
          <p>
            You may give us personal information when you apply for a Digital
            Outcomes program, register for the Platform or otherwise submit
            information to use through the Services or other communications with
            us. For example: We may collect Identity Data,
            Professional/Biographical Data, and Content when you create a
            profile on our Site (“Profile Data”). We may collect Commercial
            Data, Identity Data, and Financial Data when you enter into a
            commercial transaction or register for an event (“Transaction
            Data”). We may collect Identity Data, Professional/Biographical
            Data, Content, Government ID, Protected Class Data, or Special
            Category Data when you submit an application to one of our programs
            (“Application Data”).
          </p>
          <p>Event Recordings</p>
          <p>
            As part of our Services, we host, coordinate, and operate community
            events, demo days, mentor sessions, and other public or private
            events (“Events“) where you may engage with Digital Outcomes
            founders, teams, sponsors, investors, and other community members.
            We may create audio or visual recordings of these events, take
            photographs, conduct interviews or surveys, or conduct similar
            activities at these Events (“Event Recordings“), and we will collect
            and process Audio/Visual Data incorporated into these Event
            Recordings.You may object to the processing of your personal
            information in connection with Event Recordings during the Event by
            speaking with organizers of the Event, or afterwards, by contacting
            us using the information below. Please note, the processing of
            personal information in connection with Event Recordings may be
            subject to additional terms and conditions to which you are a party
            (e.g. a release or consent) that will supersede the nature of
            processing or individual rights described in this Privacy Policy to
            the extent in conflict.
          </p>
          <p>Information from Social Networking Sites</p>
          <p>
            Our Site includes interfaces that allow you to connect with social
            networking sites (each a “SNS“). If you connect to a SNS through our
            Site you authorize us to access, use and store the information that
            you agreed the SNS could provide to us based on your settings on
            that SNS. We will access, use and store that information in
            accordance with this Privacy Policy. You can revoke our access to
            the information you provide in this way at any time by amending the
            appropriate settings from within your account settings on the
            applicable SNS.
          </p>
          <p>Information we get from others</p>
          <p>
            We may subscribe to databases or contract with third party data
            providers from which we collect personal information such as Contact
            Data, Professional/Biographical Data, Inference Data, and other
            personal data regarding individuals that may be interested in our
            Events and programs. We may also collect, and combine with other
            personal data we maintain, personal data that is publicly available,
            such as that provided by business networking sites and databases,
            information related to the organization for which you work and
            personal interests and skill sets, and other similar information. We
            also may obtain information from those in the Digital Outcomes
            network such as our event organizers, contractors, and local
            representatives who have agreed to a relationship with us in order
            to deliver our Services. This may include information about
            potential interest in our programs, company and individual progress,
            interest in local events, and attendance at other similar events.
          </p>
          <p>Information automatically collected</p>
          <p>
            We may automatically collect personal information such as
            Device/Network Data when you access and use our Site. For example,
            we may log your computer or mobile device operating system name and
            version, manufacturer and model, browser type, browser language,
            screen resolution, the website you visited before browsing to our
            Site, pages you viewed, how long you spent on a page, access times
            and information about your use of and actions on our Site. We
            collect this information about you using cookies. Please refer to
            the Cookies and Similar Technologies section below for more details.
          </p>
          <p>Changes to your personal information</p>
          <p>
            It is important that the personal information we hold about you is
            accurate and current. Please let us know if your personal
            information changes during your relationship with us by updating
            your registration profile or emailing us at hello@digitaloutcomes.io
          </p>
          <h4>How We Use Your Information</h4>
          <p>To provide the Services you request</p>
          <p>
            If you use our Site, sign up for, or request to participate in a
            particular Service, we will process your personal information you
            give us or that we receive from relevant parties as necessary or
            appropriate for us to provide that Service. For example, we may
            process your personal information as follows: (i) we process
            Device/Network Data to operate, maintain, administer and improve our
            Site, (e.g., to show the content you request, secure the Site, and
            understand how users interact with the Site); (ii) we process
            Profile Data, Transaction Data, and Application Data to create,
            manage, and communicate with you regarding an account you may have
            on our Services, including by sending you service announcements,
            technical notices, updates, security alerts, and support and
            administrative messages; (iii) we process Profile Data, Transaction
            Data, and Application Data, Inference Data and other personal
            information to better understand your needs and interests, and
            personalize your experience with the Services; and (iv) we process
            personal information related to support and maintenance for our
            Services that you may request.
          </p>
          <p>In connection with Marketing Communications</p>
          <p>
            If you request information from us, register on our Site, or
            participate in our surveys, promotions or events, where allowed by
            law, we or our third party partners, mentors, and members of the
            Digital Outcomes network may use your personal information, such as
            Identity Data and Contact Data, as well as Inference Data or Profile
            Data, to send you updates, newsletters, surveys, offers, and other
            promotional materials, including communications that may be targeted
            to you based on your personal information, as permitted by law. We
            also may, from time to time, contact you about a particular offering
            that may be of interest to you based on publicly available
            information, or information obtained through the Digital Outcomes
            network, Events you've attended, or your participation in Digital
            Outcomes Services. You will have the ability to opt out of such
            communications.
          </p>
          <p>To document and promote Digital Outcomes and our events</p>
          <p>
            If we create any Event Recordings, we or our event sponsors or
            participants may use those Event Recordings (including any
            Audio/Visual Data therein) as part of our marketing and promotional
            activities. Further, we may also publish the Event Records,
            including Audio/Visual Data or related Identity Data therein, in
            connection with our documentation of or publicity relating to those
            Events, for example, on the Digital Outcomes website or a relevant
            Event-specific page. We may seek your consent or a release from you
            in the event the nature of our processing requires such consent or
            release. However, in certain circumstances, we may process or
            publish certain video, images, etc. without your consent, such as
            when we publish on an Event page certain Event Recordings that
            include images of groups or crowds in attendance at an Event.
          </p>
          <h4>Cookies and Similar Technologies</h4>
          <p>
            What are cookies and similar technologies? We may collect personal
            information using “cookies“. Cookies are small data files stored on
            the hard drive of your computer or mobile device by a website. We
            may use both session cookies (which expire once you close your web
            browser) and persistent cookies (which stay on your computer or
            mobile device until you delete them) to provide you with a more
            personal and interactive experience on our Site. We use two broad
            categories of cookies: (i) first party cookies, served directly by
            us to your computer or mobile device, which we use to recognize your
            computer or mobile device when it revisits our Site; and (ii) third
            party cookies, which are served by service providers on our Site,
            and can be used by such service providers to recognize your computer
            or mobile device when it visits other websites or online services.
            Pixel tags- We may also use technologies that function similarly to
            cookies called pixel tags (which are also known as web beacons and
            clear GIFs) to track the actions of users on our Site. Unlike
            cookies, which are stored on the hard drive of your computer or
            mobile device by a website, pixel tags are embedded invisibly on
            webpages. Pixel tags can be used, for example, to measure the
            success of our marketing campaigns and compile statistics about
            usage of the Site, so that we can better understand how users
            interact with our Site.
          </p>
          <h4>How we use cookies and similar technologies</h4>
          <p>
            Our Site may collect personal information using the following types
            of cookies and similar technologies and process that data for the
            purposes set out below:
          </p>
          <p>Essential Cookies</p>
          <p>
            These cookies and similar technologies are essential to provide you
            with services available through our Site and to enable you to use
            some of its features (e.g. to keep you logged in as you navigate
            between web pages). Without these cookies, the services that you
            have asked for cannot be provided, and we only use these cookies to
            provide you with those services. These cookies and similar
            technologies collect personal information, such as unique IDs, IP
            addresses, device information, OS/browser type, and other similar
            data, that are used to create information about traffic to our Site
            and how users use our Site. The information gathered may include the
            number of visitors to our Site, the websites that referred them to
            our Site, the pages they visited on our Site, what time of day they
            visited our Site, whether they have visited our Site before, and
            other similar information.
          </p>
          <p>Analytics and Performance Cookies</p>
          <p>
            We use this information to help operate our Site more efficiently,
            to gather broad demographic information and to monitor the level of
            activity on our Site. For example, we use Google Analytics to
            improve how our Site works, so that we can understand usage patterns
            on the Site, and similar purposes. You can find out more information
            about Google Analytics cookies here and about how Google protects
            your data here. You can prevent the use of Google Analytics relating
            to your use of our Site by downloading and installing the browser
            plugin available here. To learn more about other similar
            technologies we use and how you can opt out, you may contact us at
            hello@digitaloutcomes.io
          </p>
          <p>Social Media Cookies</p>
          <p>
            These cookies and similar technologies are used when you share
            information using a social media sharing button or “like” button on
            our Site or you link your account or engage with our content on or
            through a social networking website such as Facebook or Twitter. The
            social network will record that you have done this and may collect
            additional personal information about you, or link it to other
            personal information they hold about you, as described further by
            the social media service. Please see the applicable privacy policy
            for more detail.
          </p>
          <p>Retargeting Interest-based Advertising</p>
          <p>
            These cookies and similar technologies are used for “retargeting”
            and for interest-based advertising. Interest-based advertising
            includes various parties and services providers, including third
            party data controllers, engaged in the processing of personal data
            in connection with personalized advertising. These parties may
            collect personal information such as unique IDs, IP addresses,
            device information, OS/browser type, and other similar data, as well
            as information about the ads you see and view to develop and assess
            aspects of a profile about you to deliver more relevant
            advertisements and offers, and determine whether and how ads you see
            are effective. These third parties may augment your profile with
            demographic and other preferences data derived from these
            observations, and may also track whether you view, interact with, or
            how often you have seen an ad, or whether you complete a purchase
            for a good or services you were shown in an advertisement. These
            parties may be able to identify you across sites, devices, and over
            time. We also use ad “retargeting” so that you can see
            advertisements from us on other websites. For example, we may share
            with Google certain personal information (such as unique user ID) in
            connection with advertising provided through Google's “Customer
            Match” service. These technologies and the data they process may be
            used by advertisers to deliver ads on their services based on
            content you have viewed on our Sites, or that we believe you would
            be interested in, for example, based on Events you have attended or
            products/services you have purchased from us. These tracking
            technologies may also help prevent you from seeing the same
            advertisements too many times, and help us understand whether you
            have interacted with or viewed ads we've delivered to you. This
            collection and ad targeting takes place both on our Sites and on
            third-party websites (e.g. Google) that participate in the ad
            network, e.g. any advertisements delivered by that ad network on a
            third party website. To learn about the specific providers we use
            for these services, please contact us at hello@digitaloutcomes.io
          </p>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default Privacypolicy;

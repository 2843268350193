import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Editor from "../../components/Editor/Editor/Editor";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import { parseIfJson } from "../../utils/parsetJson";
import { useParams } from "react-router-dom";
import * as Showdown from "showdown";
import Cookies from "universal-cookie";
import "./Review.css";
import { axiosInstance } from "../../utils/config";
import EditorOptions from "../../components/Editor/EditorOptions/EditorOptions";
import { addCommentLineWidget } from "../../components/CommentWidget/CommentWidget";
import Footer from "../../components/Footer/Footer";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import Toast from "../../components/Toast/Toast";
import Modal from "../../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RelatedSnippets from "../../sections/RelatedSnippets/RelatedSnippets";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

function Review() {
  const params = useParams();
  const [snippet, setSnippet] = useState({});
  const [comments, setComments] = useState([]);
  const [currentLine, setCurrentLine] = useState(0);
  const snippetId = params.id;
  const [success, setSuccess] = useState("");
  const [loadingComments, setLoadingComments] = useState(false);
  const { isLoading, totalSnippets } = useSelector((state) => state.snippets);
  const [error, setError] = useState("");
  const widgets = [];
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // New state to manage delete confirmation modal

  const navigate = useNavigate();

  const fetchSnippetAndComments = async () => {
    try {
      let snippetRes = await axiosInstance.get(`/snippets/${snippetId}`);
      setSnippet(snippetRes.data);

      // Query for the comments
      setLoadingComments(true);
      let commentsRes = await axiosInstance.get(
        `/snippets/${snippetId}/comments`
      );
      setComments(commentsRes.data);
      setLoadingComments(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSnippetAndComments();
  }, [params, snippetId]);

  useEffect(() => {
    setError("");
    setSuccess("");
  }, [currentLine]);

  const createCommentWidgets = (cm) => {
    comments?.forEach((comment) => addCommentLineWidget(cm, comment));
  };

  const removeInputWidgets = (cm) => {
    for (let i = 0; i < widgets.length; i++) {
      cm.removeLineWidget(widgets[i]);
    }
  };

  const addInputLineWidget = (cm, event) => {
    setCurrentLine(event.line);
    removeInputWidgets(cm);
    const line = event.line;

    let div = document.createElement("div");
    const root = ReactDOM.createRoot(div);
    root.render(<InputWidget cm={cm} line={line} />);
    widgets.push(cm.addLineWidget(line, div));
  };

  const deleteSnippet = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/snippets/${snippetId}`);
      setSuccess("Snippet deleted successfully!");
      setShowDeleteModal(false); // Close modal after deletion
      navigate(-1); // Redirect to another page after deletion
    } catch (error) {
      setError("Failed to delete snippet");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true); // Show delete confirmation modal
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false); // Hide delete confirmation modal
  };

  const [isToggled, setIsToggled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const ToggleView = () => {
    setIsToggled(!isToggled);
  };

  const InputWidget = ({ cm, line }) => {
    const [value, setValue] = useState("");
    const [selectedTab, setSelectedTab] = useState("write"); // or preview
    const [commenting, setCommenting] = useState(false);

    const addComment = (comment) => {
      if (!comment.text) {
        setError("Comment must not be empty!");
        return;
      }
      setCommenting(true);
      axiosInstance
        .post("/comments/", comment)
        .then((response) => {
          setCommenting(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 300);
          setSuccess("Your comment is added 🎉");
        })
        .catch((e) => {
          setCommenting(false);
          console.log(e);
        });
    };
    const handleChange = (value) => {
      if (!userCookie) return setShowModal(true);
      setValue(value);
    };
    return (
      <div className="widget-container">
        <ReactMde
          className="review__textarea"
          value={value}
          onChange={handleChange}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
        <div className="px-1">
          <button
            className="primary-bg text-white rounded-md h-[26px] px-2"
            type="dashed"
            onClick={() => removeInputWidgets(cm)}
          >
            Cancel
          </button>
          &nbsp;
          <button
            className="primary-bg text-white rounded-md h-[26px] px-2"
            onClick={() =>
              addComment({
                line,
                text: value,
                snippetId,
              })
            }
          >
            {commenting ? "Commenting..." : "Add Comment"}
          </button>
        </div>
      </div>
    );
  };

  const cookies = new Cookies();
  const [userCookie, setUserCookie] = useState(cookies.get("token"));
  useEffect(() => {
    setUserCookie(cookies.get("token"));
  }, [cookies]);

  return (
    <div className="review-page">
      <LightNavBar active="explore" />
      {error && <Toast message={error} type="error" />}
      {success && <Toast message={success} type="success" />}
      {showModal && (
        <Modal>
          <div className="card w-[30rem] flex items-center justify-center px-4 h-[200px] rounded-md shadow-md bg-white">
            <p className="text-center">
              Please, first login to add a comment
              <div className="">
                <button
                  className="px-2 primary-bg rounded-sm m-2 text-white hover:bg-blue-700"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="px-2 bg-red-600 rounded-sm m-2 text-white hover:bg-red-700"
                >
                  Cancel
                </button>
              </div>
            </p>
          </div>
        </Modal>
      )}
      <div className="sub-header mx-4 flex flex-wrap items-end w-[73%] justify-between relative">
        <div className="flex flex-col justify-start">
          <span className="flex items-center">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8 0H1.2C0.54 0 0 0.54 0 1.2V12L2.4 9.6H10.8C11.46 9.6 12 9.06 12 8.4V1.2C12 0.54 11.46 0 10.8 0ZM6.942 5.742L6 7.8L5.058 5.742L3 4.8L5.058 3.858L6 1.8L6.942 3.858L9 4.8L6.942 5.742Z"
                fill="#BFBFBF"
              />
            </svg>
            <small className="text-muted ml-1">
              {snippet.comments?.length} reviews
            </small>
          </span>
          <b>{snippet.title ? snippet.title : "..."}</b>
          <small className="text-muted">
            Click on any line to add a review/comment
          </small>
        </div>
        <div className="flex items-center relative right-0">
          <input
            type="checkbox"
            checked={isToggled}
            onClick={() => ToggleView()}
            className="mr-1 cursor-pointer"
            name=""
            id="toggle-view-comments"
          />
          <label htmlFor="toggle-view-comments" className="text-xs">
            Turn reviews off (hide comments)
          </label>
          &nbsp;&nbsp;
          <button
            onClick={handleDeleteClick}
            className={`btn-delete focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 ${
              isDeleting ? "btn-disabled" : ""
            }`}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete Snippet"}
          </button>
        </div>
      </div>

      {/* Modal for delete confirmation */}
      {showDeleteModal && (
        <Modal>
          <div className="delete-confirmation-box w-[30rem] flex flex-col items-center justify-center px-4 h-[200px] rounded-md shadow-md bg-white">
            <p className="text-center">
              Are you sure you want to delete this snippet?
            </p>
            <div className="flex justify-center mt-4">
              <button
                className="px-4 py-2 primary-bg text-white rounded-md mr-2"
                onClick={deleteSnippet}
              >
                Yes, Delete
              </button>
              <button
                className="px-4 py-2 bg-gray-600 text-white rounded-md"
                onClick={handleCancelDelete}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className="w-full flex flex-wrap">
        <div className="font-roboto-mono editor-container my-4 mx-4 w-[73%]">
          <EditorOptions language={snippet.language} />
          <Editor
            key={`${isToggled}-${comments.length}`}
            text={parseIfJson(snippet.text)}
            language={snippet.language}
            // when user clicks on gutter, display comment widget if user is logged in, or display login modal id user is not logged in
            onCursor={addInputLineWidget}
            // setTimeout required to avoid JS Execution race condition with CodeMirror
            onMount={
              !isToggled
                ? (cm) => setTimeout(() => createCommentWidgets(cm), 0)
                : () => {}
            }
          />
        </div>
        <div className="w-[20%] related-snippets-container">
          <RelatedSnippets />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Review;

import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import InputField from "../../components/InputField/InputField";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import TextArea from "../../components/TextArea/TextArea";
import Select from "react-select";
import { Languages } from "../../assets/data";
import BlueButton from "../../components/BlueButton/BlueButton";
import { Controlled as CodeMirror } from "react-codemirror2";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/elegant.css";
import "codemirror/theme/material.css";
import { axiosInstance } from "../../utils/config";
import Toast from "../../components/Toast/Toast";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import RelatedSnippets from "../../sections/RelatedSnippets/RelatedSnippets";
import axios from "axios";

function Submission() {
  const [text, setText] = useState("");
  const [newSnippetId, setNewSnippetId] = useState();
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [language, setLanguage] = useState();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  //Show Login page if not logged in.
  const cookies = new Cookies();
  const userCookie = cookies.get("token");
  useEffect(() => {
    if (!userCookie) {
      navigate("/login");
    }
  }, [userCookie]);

  const handleSubmission = () => {
    setSubmitting(true);
    let parsedText = text;
    if (!title || !parsedText) {
      if (!title && !parsedText) {
        setError("Snippet / Title must not be empty!");
        setSubmitting(false);
        return;
      }
      if (!title) {
        setError("Title must not be empty!");
        setSubmitting(false);
      }
      if (!parsedText) {
        setError("Snippet must not be empty!");
        setSubmitting(false);
      }
      return;
    }
    axiosInstance
      .post(
        "/snippets/",
        {
          title,
          text: JSON.stringify(text),
          language,
          headers: {
            Authorization: `Token ${userCookie}`,
          },
        },
        { withCredentials: true }
      )
      .then((data) => {
        setNewSnippetId(data.data?.id);
        setSubmitting(false);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };
  const onCursor = () => {};
  const onMount = () => {};
  const onGutterClick = () => {};
  const setRef = () => {};
  return (
    <div className="relative">
      <LightNavBar active="none" />
      {error && <Toast message={error} type="error" />}
      {showModal && <ConfirmationModal id={newSnippetId} />}
      <div className="page-container px-10 content flex itemsc-center justify-around">
        <div className="right w-7/12 mb-8">
          <form action="">
            <h6 className="text-black my-6 font-bold">Make a new submission</h6>
            <InputField
              classNames="my-7"
              label="Title"
              value={title}
              handleChange={(e) => setTitle(e.target.value)}
              placeholder="Choose a short descriptive title"
            />
            <div className="select">
              <label htmlFor="select">Language</label>
              <Select
                className=""
                id="select"
                onChange={(selectedOption) => setLanguage(selectedOption.value)}
                classNamePrefix="select"
                defaultValue={Languages[0]}
                msg
                isClearable={true}
                isSearchable={false}
                name="color"
                options={Languages}
              />
            </div>
            <InputField
              value={position}
              handleChange={(e) => setPosition(e.target.value)}
              classNames="my-7"
              label="What position are you interviewing for?"
              placeholder="e.g. Junior, senior, Principal Engineer..."
            />

            <TextArea
              classNames="my-16"
              innerClassNames="h-[89px]"
              label="Description"
              placeholder="Describe your question here"
            />
          </form>
          <div className="editor-field">
            <span>Solution</span>
            <CodeMirror
              ref={setRef}
              value={text ? text : "\n\n\n\n\n\n\n\n\n"}
              onBeforeChange={(editor, data, value) => {
                setText(value);
              }}
              onCursor={onCursor}
              onGutterClick={onGutterClick}
              className="editor text-sm"
              editorDidMount={onMount}
              options={{
                readOnly: false,
                mode: language,
                lineNumbers: true,
                lineWrapping: true,
              }}
            />
          </div>
          <p className="my-6 text-sm">
            In order for us to give you feedback on your code, please ensure
            that you submit the complete question along with your solution.
            Submissions with inadequate context will be ignored.
          </p>
          <div onClick={handleSubmission}>
            <BlueButton>
              {submitting ? "Submitting..." : "Submit your code"}
            </BlueButton>
          </div>
        </div>
        <div className="left w-3/12">
          <RelatedSnippets />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Submission;

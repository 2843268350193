import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "./Footer.css";
import { useParams } from "react-router-dom";

function Footer() {
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  return (
    <footer className="bg-[#0041CA] p-10 flex justify-between font-poppins items-start">
      <div className="logo-section flex-[3]">
        <img src={Logo} alt="" />
      </div>
      <div className="links-section flex flex-[2] items-start justify-between">
        <div className="quick-links">
          <b className="text-sm text-white">Code Reviews</b>
          <ul className="my-2 text-xs">
            <li>
              <Link to="/featured/java">Java</Link>
            </li>
            <li>
              <Link to="/featured/python">Python</Link>
            </li>
            <li>
              <Link to="/featured/php">PHP</Link>
            </li>
            <li>
              <Link to="/featured/ruby">Ruby</Link>
            </li>
            <li>
              <Link to="/featured/javascript">Javascript</Link>
            </li>
            <li>
              <Link to="/featured/clike"> C/C++</Link>
            </li>
            <li>
              <Link to="/featured/rust"> Rust</Link>
            </li>
            <li>
              <Link to="/featured/typescript"> Typescript</Link>
            </li>
            <li>
              <Link to="/featured/go"> Go</Link>
            </li>
            <li>
              <Link to="/featured/julia"> Julia</Link>
            </li>
            <li>
              <Link to="/featured/swift"> Swift</Link>
            </li>
            <li>
              <Link to="/featured/kotlin"> Kotlin</Link>
            </li>
          </ul>
        </div>
        <div className="mobile-footer-right flex justify-around w-7/12">
          <div className="quick-links">
            <b className="text-sm text-white">Resources</b>
            <ul className="my-2 text-sm">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/comingsoon">About</Link>
              </li>
              <li>
                <Link to="/comingsoon">Blog</Link>
              </li>
              <li>
                <Link to="/comingsoon">Interview</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links">
            <b className="text-sm text-white">Follow us</b>
            <ul className="my-2 text-sm">
              <li>
                <Link to="/comingsoon">LinkedIn</Link>
              </li>
              <li>
                <Link to="/comingsoon">Twitter</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

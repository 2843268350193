import React, { useState } from "react";
import Footer from "../components/Footer/Footer";
import "./FormsLayout.css";
import { UilPlus, UilSignOutAlt } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo_dark.png";
import GreetingsIcon from "../assets/icons/greetings.svg";
import SearchField from "../components/SearchField/SearchField";
import BlueButton from "../components/BlueButton/BlueButton";
import useWindowDimensions from "../utils/useWindowDimensions";
import MobileSideBar from "../components/Mobile/MobileSideBar/MobileSideBar";
import MobileLightNavBar from "../components/Mobile/MobileLightNavBar/MobileLightNavBar";

function FormsLayout({ children, welcomeText, activeTab }) {
  const { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  return (
    <div className="forms-layout-container relative">
      {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
      <div className="relative font-poppins">
        <div className="flex h-[100vh] top-0 relative">
          <div
            className={`forms-part w-6/12 bg-white ${
              width > 834 && "pt-4 px-3"
            }`}
          >
            {width < 834 ? (
              <MobileLightNavBar
                styles="my-1 h-[40px]"
                toggleShowSideBar={toggleShowSideBar}
              />
            ) : (
              <div className="forms-nav-items flex black-color-all items-center w-full justify-around">
                <Link to="/" className="cursor-pointer">
                  <img src={Logo} alt="" />
                </Link>
                <Link
                  to="/featured"
                  className={`${activeTab === "explore" && "active"}`}
                >
                  Explore
                </Link>
                {/*<Link
                  to="/waitlist"
                  className={`${activeTab == "waitlist" && "active"}`}
                >
                  Join waitlist
                </Link> */}
                <Link
                  to="/contact"
                  className={`${activeTab === "contact" && "active"}`}
                >
                  Contact us
                </Link>
              </div>
            )}

            <div className="form-container flex items-center w-6/12 m-auto h-full justify-center flex-col">
              {/* Form Goes here */}
              {children}
            </div>
          </div>
          <div className="forms-banner flex flex-col items-center justify-between w-6/12">
            <div className="right flex items-center w-10/12 my-3 justify-center gap-5 m-auto">
              <SearchField bgColor="light-grey-bg" />
              <BlueButton preIcon={<UilPlus size="15" />} redirectTo={"/login"}>
                Submit
              </BlueButton>
              <BlueButton
                redirectTo={"/login"}
                preIcon={<UilSignOutAlt size="15" />}
              >
                Login
              </BlueButton>
              <BlueButton
                redirectTo={"/register"}
                preIcon={<UilSignOutAlt size="15" />}
              >
                Register
              </BlueButton>
            </div>
            <div className="greetings flex items-center justify-around bg-[rgba(255,255,255,0.56)] rounded-full py-3 px-5 font-medium gap-3">
              <img src={GreetingsIcon} alt="Greeting hand icon" />
              <span className="text-sm font-bold">{welcomeText}</span>
            </div>
            <div className="slider h-[75px] flex">
              <div className="left flex-[1] primary-bg p-2 text-xl text-white flex items-center justify-center font-bold">
                01
              </div>
              <div className="right flex-[6] bg-[rgba(249,249,249,0.8)] px-4 gap-2 flex items-center justify-center">
                <p className="text-xs font-medium m-0">
                  {" "}
                  If you are looking for a job, then you should go over code
                  posted by others and study the comments on them.
                </p>
                <div className="slider-icons flex items-center gap-2">
                  <svg
                    className="cursor-pointer"
                    width="7"
                    height="13"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 1L1 6.5L6 12"
                      stroke="#393939"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <svg
                    width="2"
                    height="30"
                    viewBox="0 0 2 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1V29"
                      stroke="#9D9D9D"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>

                  <svg
                    className="cursor-pointer"
                    width="7"
                    height="13"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 12L6 6.5L1 1"
                      stroke="#393939"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FormsLayout;

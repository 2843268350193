import React from "react";
import { useNavigate } from "react-router-dom";

function BlueButton({
  classNames,
  children,
  preIcon,
  redirectTo,
  handleClick,
  disabled,
}) {
  const navigate = useNavigate();
  return (
    <button
      disabled={disabled}
      onClick={() => navigate(redirectTo ? redirectTo : "")}
      className={`${classNames} flex items-center text-white ${
        disabled && "opacity-50 cursor-not-allowed"
      } primary-bg rounded-md px-3 py-[0.3rem] black-color`}
    >
      <span className="mr-1 text-white">{preIcon}</span>
      <small className="text-white">{children}</small>
    </button>
  );
}

export default BlueButton;

import React from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from "../../../assets/images/logo.png";
import SearchButtonMobile from '../../SearchButtonMobile/SearchButtonMobile';

function NavBarMobile({ toggleShowSideBar, styles }) {
    const navigate = useNavigate();
    return (
        <div className={`${styles} px-2 flex items-center justify-between gap-2`}>
            <div className="flex items-center ">
                <div className="p-2" onClick={() => toggleShowSideBar()}>
                    <svg width="15" height="15" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1ZM12 5C12 5.55228 11.5523 6 11 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H11C11.5523 4 12 4.44772 12 5ZM0 9C0 9.55229 0.447715 10 1 10H11C11.5523 10 12 9.55229 12 9C12 8.44771 11.5523 8 11 8H1C0.447715 8 0 8.44771 0 9Z" fill="white" />
                    </svg>
                </div>
                <img onClick={() => navigate("/")} src={Logo} alt="logo" className='w-[145px] h-[19px]' />
            </div>
            <SearchButtonMobile />
        </div>
    )
}

export default NavBarMobile